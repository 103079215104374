import React, {useState} from 'react';
import {
    BooleanField,
    Datagrid,
    FunctionField,
    Pagination,
    ReferenceManyField,
    Show,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useListContext,
    usePermissions,
    useRecordContext,
    useRefresh,
    WithRecord
} from 'react-admin';
import {useTranslation} from "react-i18next";
import {Avatar, FormHelperText, MenuItem, Typography} from "@mui/material";
import TranslationTable from "../components/TranslationTable";
import hasPermission from "../auth/hasPermission";
import {axiosApiInstance} from "../AxiosUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import {useStyles} from "../styles";
import Grid from "@mui/material/Grid";
import CustomTextField from "../components/CustomTextField";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const ListActions = () => {

    const {t} = useTranslation();

    const {permissions} = usePermissions();

    const refresh = useRefresh();

    const record = useRecordContext();

    // Ids that can be selected in the datagrid of the to be mailed sponsors
    const {selectedIds} = useListContext();

    const [sending, setSending] = useState(false);

    const sendUpdatePostWaitingMail = () => {
        const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');
        console.log(JSON.stringify(selectedIds));

        if (record && selectedIds) {
            let formData = new FormData();
            formData.append("selectedIds", selectedIds);

            setSending(true);
            axiosApiInstance.post(
                `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/updatePostWaiting/${record.id}`,
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
            ).then((response) => {
                if (response.status === 200) {
                    setSending(false);
                    refresh();
                }
            }).catch((reason) => {
                setSending(false);
            })
        }
    }

    return (
        <TopToolbar>
            <LoadingButton
                onClick={sendUpdatePostWaitingMail}
                loading={sending}
                variant="contained"
                style={{marginTop: -6}}
            >{t('Send mail')}</LoadingButton>
        </TopToolbar>
    );
}

const CharityData = (props) => {
    const {t} = useTranslation();

    const classes = useStyles();

    const {permissions} = usePermissions();

    const [sending, setSending] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [mailsSent, setMailsSent] = useState(0);
    const [triedSending, setTriedSending] = useState(0);

    const record = useRecordContext();

    const [nrOfWeeks, setNrOfWeeks] = React.useState(2);

    const handleNrOfWeeksChange = (event) => {
        setNrOfWeeks(event.target.value);
    };

    const triggerNewPostMail = () => {
        const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');
        if (record) {
            setSending(true);
            setSuccessAlert(false);
            setWarningAlert(false);
            axiosApiInstance.get(
                `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/trigger/mail/${record.id}/${nrOfWeeks}`
            ).then((response) => {
                console.log(JSON.stringify(response));
                if (response.status === 200) {
                    setSending(false);
                    setSuccessAlert(true);
                    setWarningAlert(false);
                    if(response.data && response.data.send_mail_info) {
                        setMailsSent(response.data.send_mail_info.mailsSent);
                        setTriedSending(response.data.send_mail_info.triedSending);
                    }
                } else {
                    setSuccessAlert(false);
                    setWarningAlert(true);
                }
            }).catch((reason) => {
                setSending(false);
                setSuccessAlert(false);
                setWarningAlert(true);
            })
        }
    }

    return (
        <div className="showie">
            <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                <Grid item xs={12}>
                    <div className="custom-box borderGreen">
                        <WithRecord
                            render={record =>
                                <div className="rowit">
                                    <Avatar sx={{height: 100, width: 100, marginRight: '16px'}}
                                            src={`${process.env.REACT_APP_CDN_URL}/${record.logo_name}.${record.ext}`}/>
                                    <div>
                                        <Typography sx={{fontWeight: 700, fontSize: '24px'}}>
                                            {record.charity_name}
                                        </Typography>
                                        {record.project_number && record.project_number !== '' && record.project_number !== null &&
                                            <Typography sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                fontStyle: 'italic',
                                            }}>({t('Project number')} : {record.project_number})</Typography>
                                        }
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </Grid>
            </Grid>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label={t('Details')}>
                    <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                        <Grid item xs={12} lg={6}>
                            <div class="custom-box">
                                <Typography variant="h6" gutterBottom>{t('General info')}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <CustomTextField source="short_name" label={t('Short name')}
                                                         sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField source="sponsorship_amount" label={t('Sponsorship amount')}
                                                         sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {t('Is active')}
                                            </Typography>
                                            <BooleanField source="active"/>
                                        </div>
                                    </Grid>
                                    {hasPermission(permissions, ["view"], "umbrella") &&
                                        <Grid item xs={2}>
                                            <div>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    Demo
                                                </Typography>
                                                <BooleanField source="demo_charity"/>
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                            <div class="custom-box" style={{marginTop: '24px'}}>
                                <Typography variant="h6" gutterBottom>{t('Mail settings')}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <CustomTextField source="email" label={t('Email')} sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {t('Send new post mail?')}
                                            </Typography>
                                            <BooleanField source="send_new_post_mail"/>
                                        </div>
                                    </Grid>
                                    <WithRecord
                                        render={record =>
                                            <>
                                                {record.send_new_post_mail &&
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ ml: 2, minWidth: 120 }}>
                                                            <Select
                                                                value={nrOfWeeks}
                                                                onChange={handleNrOfWeeksChange}
                                                            >
                                                                <MenuItem value={1}>1</MenuItem>
                                                                <MenuItem value={2}>2</MenuItem>
                                                                <MenuItem value={3}>3</MenuItem>
                                                                <MenuItem value={4}>4</MenuItem>
                                                                <MenuItem value={5}>5</MenuItem>
                                                                <MenuItem value={6}>6</MenuItem>
                                                            </Select>
                                                            <FormHelperText>{t('# weeks back')}</FormHelperText>
                                                        </FormControl>
                                                        <LoadingButton
                                                            onClick={triggerNewPostMail}
                                                            loading={sending}
                                                            variant="contained"
                                                            sx={{ml: 2}}
                                                        >{t('Send new post mail')}</LoadingButton>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    />
                                    {successAlert ? <Alert severity="success">
                                        <AlertTitle>Succes</AlertTitle>
                                        Er zijn { mailsSent } mail(s) verstuurd. (Geprobeerd #{ triedSending })
                                    </Alert> : <></>}

                                    {warningAlert ? <Alert severity="warning">
                                        <AlertTitle>Warning</AlertTitle>
                                        Bij het versturen van de mails is iets misgegaan.
                                    </Alert> : <></>}
                                </Grid>
                            </div>
                            <div className="custom-box" style={{marginTop: '24px'}}>
                                <Typography variant="h6" gutterBottom>{t('Address')}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <CustomTextField source="city" label={t('City')} sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField source="country" label={t('Country')}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField source="nr_of_countries" label={t('Nr of countries')}
                                                         sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField source="nr_of_projects" label={t('Nr of projects')}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField source="lat" label="Lat" sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField source="lng" label="Lng"/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <div class="custom-box">
                                <Typography variant="h6" gutterBottom>{t('Project info')}</Typography>
                                {hasPermission(permissions, ["view"], "charity.location") &&
                                    <Grid item xs={12}>
                                        <CustomTextField source="location_id" label={t('Location')}/>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <CustomTextField source="description" label={t('Description')}
                                                     sx={{marginRight: '6px'}} multiline={true}/>
                                </Grid>
                            </div>
                            <div className="custom-box" style={{marginTop: '24px'}}>
                                <Typography variant="h6" gutterBottom>{t('Alert info')}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <CustomTextField source="days_orange_alert" label={t('Days orange alert')}
                                                         sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField source="days_red_alert" label={t('Days red alert')}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={t('Translations')}>
                    <FunctionField
                        render={(record) => {
                            return (
                                <TranslationTable translations={record.translations}/>
                            );
                        }}
                    />
                </TabbedShowLayout.Tab>
                {hasPermission(permissions, ["*"], "mailLogs") &&
                    <TabbedShowLayout.Tab label={t('Mail sponsors')}>
                        <ReferenceManyField label={t('Sponsor list')} reference="updateWaitingList" target="charity_id" perPage={25} pagination={<Pagination />}>
                            {t('Sponsor list info')}
                            <Datagrid className={classes.root} bulkActionButtons={<ListActions/>} optimized>
                                <TextField source="guardian_name" label={t('Guardian name')} sortable={false}/>
                                <TextField source="child_name" label={t('Child name')} sortable={false}/>
                                <TextField source="last_access" label={t('Last access')} sortable={false}/>
                                <TextField source="language" label={t('Language')} sortable={false}/>
                            </Datagrid>
                        </ReferenceManyField>
                        <ReferenceManyField label={t('Sponsor list')} reference="mailLogs" target="charity_id"
                                            perPage={20} pagination={<Pagination />} sort={{field: 'sent_date_time', order: 'DESC'}}>
                            {t('Sponsor list mailed')}
                            <Datagrid bulkActionButtons={false} className={classes.root}>
                                <TextField source="sent_date_time" label={t('Last mail sent')}/>
                                <TextField source="mail_type" label="Mail type"/>
                                <TextField source="recipient" label={t('Guardian name')}/>
                                <TextField source="last_access_date_time" label={t('Last access')} sortable={false}/>
                                <TextField source="sender" label={t('Sender')}/>
                                <TextField source="charity" label={t('Charity')}/>
                                <TextField source="child" label={t('Child name')}/>
                                <TextField source="subject" label={t('Subject')}/>
                                <TextField source="success" label={t('Sent successfully')}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </TabbedShowLayout.Tab>
                }
            </TabbedShowLayout>
        </div>
    );
}


const CharityShow = (props) => {
    return (
        <Show {...props}>
            <CharityData/>
        </Show>
    )
};

export default CharityShow;
