import React, {useState} from 'react';
import {
    AutocompleteArrayInput,
    BooleanInput,
    Create,
    Edit,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {useTranslation} from "react-i18next";
import {useUmbrellaChoices, useFetchUsers} from "../lib/customHooks";

const RnActionFormFields = () => {

    // We want to be able to translate the admin
    const {t} = useTranslation();

    // A volunteer belongs to a specific charity, retrieve the options here
    const umbrellaChoices = useUmbrellaChoices();

    // State to manage the search query
    const userChoices = useFetchUsers(); // Fetch all users once

    // We need the choices before we can continue
    if (!umbrellaChoices) return null;

    return (
        <SimpleForm redirect="edit">
            <TextInput source="action_identifier" label='Action identifier' validate={required()}/>
            <SelectInput label='Action type' source="action_type" choices={[
                {id: 'NATIVE', name: 'Native'},
                {id: 'WEBVIEW', name: 'WebView'},
            ]} validate={required()}/>
            <SelectInput label='Device type' source="device_type" choices={[
                {id: 'ALL', name: 'All'},
                {id: 'ANDROID', name: 'Android'},
                {id: 'IOS', name: 'iOS'},
            ]} validate={required()}/>
            <TextInput source="url" label='URL'/>
            <BooleanInput source="active" label={t('Is active')}/>
            <BooleanInput source="limit_to_users" label={t('Limit to specific users')}/>
            <AutocompleteArrayInput
                source="user_ids"
                label={t('Users')}
                choices={userChoices} // Use pre-fetched user choices
                placeholder={t('Search and select users')}
                validate={required()}
                fullWidth
            />
        </SimpleForm>
    );
}

export const RnActionEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title='Edit RN Action'>
            <RnActionFormFields/>
        </Edit>
    );
}

export const RnActionCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title='Create new RN Action'>
            <RnActionFormFields/>
        </Create>
    );
}
