import React, {useEffect, useState} from 'react';
import {
    BooleanField,
    DateField,
    FunctionField,
    required,
    SelectField,
    Show,
    usePermissions,
    useRecordContext,
    WithRecord
} from 'react-admin';
import {Avatar, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import CustomTextField from "../components/CustomTextField";
import CustomLabelField from "../components/CustomLabelField";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import {axiosApiInstance} from "../AxiosUtils";
import {useCharityChoices} from "../lib/customHooks";
import Chip from "@mui/material/Chip";

const VolunteerData = (props) => {

    const {t} = useTranslation();

    const {permissions} = usePermissions();

    // A volunteer belongs to a specific charity, retrieve the options here
    const charityChoices = useCharityChoices();

    const record = useRecordContext();

    const umbrellaRoleChoices = [
        {id: 'UMBRELLA_ADMIN', name: 'Umbrella Admin'},
    ];

    const charityRoleChoices = [
        {id: 'CHARITY_ADMIN', name: 'Charity Admin'},
        {id: 'CHARITY_VOLUNTEER', name: 'Charity Volunteer'},
    ];

    const [volunteerOnUmbrellaLevel, setVolunteerOnUmbrellaLevel] = useState(false);
    const [realmChoices, setRealmChoices] = useState([]);

    useEffect(() => {
        if (record && record.charity_model)
            setVolunteerOnUmbrellaLevel(record.charity_model.umbrella);
    }, [record]);

    useEffect(() => {
        if (volunteerOnUmbrellaLevel) {
            setRealmChoices(umbrellaRoleChoices);
        } else {
            setRealmChoices(charityRoleChoices);
        }
    }, [volunteerOnUmbrellaLevel]);

    const [resetting, setResetting] = useState(false);
    const [successMessage, showSuccessMessage] = useState(false);
    const [failureMessage, showFailureMessage] = useState(false);

    const resetPassword = () => {
        if (record) {
            const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');
            setResetting(true);
            showSuccessMessage(false);
            showFailureMessage(false);
            axiosApiInstance.post(
                `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/volunteers/${record.id}/resetPassword`
            ).then((response) => {
                setResetting(false);
                showSuccessMessage(true);
            }).catch((reason) => {
                console.log(JSON.stringify(reason));
                setResetting(false);
                showFailureMessage(true);
            })
        }
    }

    if (!record || !charityChoices) return null;    // We need the choices before we can continue

    return (
        <div class="showie">
            <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                <Grid item xs={12}>
                    <div class="custom-box borderGreen">
                        <WithRecord
                            render={record =>
                                <div class="rowit">
                                    <Avatar sx={{height: 100, width: 100, marginRight: '16px'}}
                                            src={`${process.env.REACT_APP_CDN_URL}/${record.avatar}`}/>
                                    <div>
                                        <Typography sx={{fontWeight: 700, fontSize: '24px'}}>
                                            {record.salutation ? t(record.salutation) : ''} {record.given_name} {record.family_name}
                                        </Typography>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div class="custom-box">
                        <Typography variant="h6" gutterBottom>{t('General info')}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Registration date')}
                                    </Typography>
                                    <DateField source="create_date_time" options={{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }}/>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Enabled')}
                                    </Typography>
                                    <BooleanField source="enabled"/>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Last access date')}
                                    </Typography>
                                    <DateField source="last_access_date_time" options={{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div class="custom-box" style={{marginTop: '24px'}}>
                        <Typography variant="h6" gutterBottom>{t('Contact details')}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CustomTextField source="email" label={t('Email')} sx={{marginRight: '6px'}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomLabelField source="firebase_status"
                                                  label={t('Notification registration status')}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LoadingButton
                                    onClick={resetPassword}
                                    loading={resetting}
                                    variant="outlined"
                                    sx={{ml: 2}}
                                >{t('Reset password')}</LoadingButton>
                                <div style={{marginTop: '8px'}}>
                                    {successMessage ? <Alert severity="success">
                                        {t('Password reset mail sent')}
                                    </Alert> : <></>}

                                    {failureMessage ? <Alert severity="warning">
                                        {t('Could not send password reset mail')}
                                    </Alert> : <></>}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div class="custom-box">
                        <Typography variant="h6" gutterBottom>{t('Coordinating')}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Project(s)')}
                                    </Typography>
                                    {!volunteerOnUmbrellaLevel &&
                                        <FunctionField
                                            label="Charities"
                                            render={record => (
                                                <>
                                                    {record.charity_ids.map(charityId => {
                                                        const charity = charityChoices.find(c => c.id === charityId);
                                                        return charity ? <Chip key={charity.id} label={charity.name}
                                                                               style={{margin: 2}}/> : null;
                                                    })}
                                                </>
                                            )}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Role')}
                                    </Typography>
                                    <SelectField source="realm_role" label={t('Role')} choices={realmChoices}
                                                 validate={required()}/>
                                </div>
                            </Grid>
                        </Grid>
                        {/*<Grid container spacing={2} lg={12}>*/}
                        {/*    <Grid item lg={6}>*/}
                        {/*        <WithRecord*/}
                        {/*            render={record =>*/}
                        {/*                <Box mt={2}>*/}
                        {/*                    <Typography style={{fontWeight: 500, fontSize: 12}}*/}
                        {/*                                gutterBottom>{t('Posts')}</Typography>*/}
                        {/*                    <Link to={`/posts?filter={"user_id":"${record.id}"}`}>*/}
                        {/*                        <Button*/}
                        {/*                            variant="contained">{t(`View posts for`)} {record.given_name}</Button>*/}
                        {/*                    </Link>*/}
                        {/*                </Box>*/}
                        {/*            }/>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const VolunteerShow = (props) => {
    return (
        <Show {...props} className="showie">
            <VolunteerData/>
        </Show>
    )
};


export default VolunteerShow;
