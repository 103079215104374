import React, {useState} from 'react';
import {Button, Card, Typography} from '@mui/material';
import {FunctionField} from 'react-admin';
import CropImageModal from './CropImageModal'; // Import the CropImageModal component
import {axiosApiInstance} from "../AxiosUtils";
import {useTranslation} from "react-i18next";

const ProfileImageCard = ({record, permissions}) => {
    const {t} = useTranslation();

    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const [isResetting, setResetting] = useState(false);
    const [successMessage, setShowSuccessMessage] = useState(false);
    const [failureMessage, setShowFailureMessage] = useState(false);

    const handleOpenCropModal = () => setIsCropModalOpen(true);
    const handleCloseCropModal = () => setIsCropModalOpen(false);

    const uploadCroppedImage = async (id, file) => {
        const rolePath = (permissions === 'admin' ? 'admin' : permissions === 'umbrella' ? 'umbrellad' : 'charitad');
        const formData = new FormData();
        formData.append('thumbnail_image', file);

        try {
            setResetting(true);
            setShowSuccessMessage(false);
            setShowFailureMessage(false);

            await axiosApiInstance.post(
                `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/children/${id}/upload-thumbnail-image`,
                formData
            );

            setResetting(false);
            setShowSuccessMessage(true);
        } catch (error) {
            console.error('Error uploading cropped image:', error);
            setResetting(false);
            setShowFailureMessage(true);
        }
    };

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 1,
            textAlign: 'center'
        }}>
            <Typography component="h6" variant="h6">Profile Image</Typography>
            <FunctionField
                render={() => (
                    <img
                        height="400"
                        src={`${process.env.REACT_APP_CDN_URL}/${record.profile_image}.${record.image_ext}`}
                        alt={record.name}
                    />
                )}
            />
            <Button variant="contained" onClick={handleOpenCropModal} sx={{marginTop: 2}}>
                {t('Create thumbnail from image')}
            </Button>

            {/* Messages for feedback */}
            {isResetting && <p>Uploading cropped image...</p>}
            {successMessage && <p style={{color: 'green'}}>Image cropped successfully!</p>}
            {failureMessage && <p style={{color: 'red'}}>Failed to crop image. Try again.</p>}

            <CropImageModal
                open={isCropModalOpen}
                onClose={handleCloseCropModal}
                imageUrl={`${process.env.REACT_APP_CDN_URL}/${record.profile_image}.${record.image_ext}`}
                onSave={(file) => {
                    uploadCroppedImage(record.id, file)
                        .then(() => {
                            // Close the modal only after upload completes
                            handleCloseCropModal();

                            // Trigger a reload or re-render
                            window.location.reload(); // Option 1: Reload the entire page
                        })
                        .catch((error) => {
                            console.error("Upload failed:", error);
                            // Optionally handle upload failure (e.g., show an error message)
                        });
                }}
            />
        </Card>
    );
};

export default ProfileImageCard;