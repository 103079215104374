import React from 'react';
import {
    BooleanInput,
    Create,
    Edit,
    FileInput,
    ImageField,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {useTranslation} from "react-i18next";
import useTranslatedCountries from "../lib/useTranslatedCountries";

const UmbrellaFormFields = () => {
    const {t} = useTranslation();

    const translatedCountries = useTranslatedCountries(); // Automatically detects language

    return (
        <SimpleForm redirect="list">
            <TextInput label={t('Name')} source="charity_name" validate={required()}/>
            <TextInput source="email" label={t('email')} validate={required()}/>
            <TextInput source="payment_link_url" label='Payment Link URL' type='url' style={{width: 500}}/>
            <TextInput source="city" label={t('City')} validate={required()}/>
            <SelectInput label={t('Country')} source="country"
                         choices={translatedCountries}
                         defaultValue={"NL"}
                         validate={required()}
            />
            <TextInput multiline fullWidth source="description" label={t('Description')} validate={required()}/>
            <FileInput labelSingle={`${t('Click to add or update logo')} (< 500Kb)`} source="logo"
                       label={t('Logo')} maxSize={500000} accept="image/*">
                <ImageField source="src" title={t('Logo')}/>
            </FileInput>
            <BooleanInput source="demo_charity" label='Demo'/>
            <BooleanInput source="active" label={t('Is active')}/>
            <BooleanInput source="send_new_post_mail" label={t('Send new post mail?')}/>
        </SimpleForm>
    );
}

export const UmbrellaEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit charity')} mutationMode="pessimistic">
            <UmbrellaFormFields/>
        </Edit>
    );
}

export const UmbrellaCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create new charity')} mutationMode="pessimistic">
            <UmbrellaFormFields/>
        </Create>
    );
}
