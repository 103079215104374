// src/hooks/useTranslatedCountries.js
import countries from 'i18n-iso-countries';
import {useEffect, useState} from 'react';

// Register locales once
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
countries.registerLocale(require('i18n-iso-countries/langs/hy.json'));

const customLanguageDetector = {
    detect: () => {
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) return savedLanguage;

        const lang = navigator.language || navigator.userLanguage;
        const baseLang = lang.split('-')[0];
        localStorage.setItem('userLanguage', baseLang);
        return baseLang;
    },
};

const useTranslatedCountries = () => {
    const [translatedCountries, setTranslatedCountries] = useState([]);

    useEffect(() => {
        const currentLanguageCode = customLanguageDetector.detect();

        const getTranslatedCountries = (locale) => {
            try {
                return Object.entries(countries.getNames(locale)).map(([id, name]) => ({id, name}));
            } catch {
                return Object.entries(countries.getNames('en')).map(([id, name]) => ({id, name})); // Fallback to English
            }
        };

        setTranslatedCountries(getTranslatedCountries(currentLanguageCode));
    }, []);

    return translatedCountries;
};

export default useTranslatedCountries;
