import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY
} from "react-admin";
import moment from "moment";

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&size=10&sort=id,desc
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const springDataRest = (apiUrl, httpClient = fetchUtils.fetchJson) => {

    // Mapped resources (with other names in the backend
    const resourceMapper = {
        umbrellas: "charities",
        // Add more mappings as needed
    };

    function mapResource(resource, resourceMapper) {
        return resourceMapper[resource] || resource;
    }

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        const role = localStorage.getItem('role');
        const rolePath = role === 'admin' ? 'admin' : role === 'umbrella' ? 'umbrellad' : 'charitad';
        let url = "";
        const options = {};
        switch (type) {
            case GET_LIST: {
                const {page, perPage} = params.pagination;
                const pageMinOne = page - 1;
                const {field, order} = params.sort;
                const camelField = toCamel(field);
                let filter = '';
                if (params && params.hasOwnProperty('filter')) {
                    for (let key in params.filter) {
                        filter += '&' + key + '=' + params.filter[key];
                    }
                }
                url = `${apiUrl}/${rolePath}/${resource}?page=${pageMinOne}&size=${perPage}&sort=${camelField},${order}${filter}`;
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${rolePath}/${resource}/${params.id}`;
                break;
            case GET_MANY: {
                /*const query = {
                    filter: JSON.stringify({ id: params.ids })
                };*/
                let idStr = "";
                const queryString = params.ids.map(id => idStr + `id=${id}`);
                url = `${apiUrl}/${rolePath}/${resource}?${queryString}}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const pageMinOne = page - 1;
                const {field, order} = params.sort;
                const camelField = toCamel(field);
                url = `${apiUrl}/${rolePath}/${resource}/many/${params.id}?page=${pageMinOne}&size=${perPage}&sort=${camelField},${order}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${rolePath}/${resource}/${params.id}`;
                options.method = "PUT";
                if (resource === 'children') {
                    options.body = getChildFormData(params.data);
                } else if (resource === 'guardians') {
                    options.body = getGuardianJsonData(params.data);
                } else if (resource === 'posts') {
                    options.body = getPostFormData(params.data);
                } else if (resource === 'charities' || resource === 'umbrellas') {
                    options.body = getCharityFormData(params.data, resource === 'umbrellas');
                } else {
                    options.body = JSON.stringify(params.data);
                }
                break;
            case CREATE:
                url = `${apiUrl}/${rolePath}/${resource}`;
                if (resource === 'children') {
                    options.body = getChildFormData(params.data);
                } else if (resource === 'guardians') {
                    options.body = getGuardianJsonData(params.data);
                } else if (resource === 'posts') {
                    options.body = getPostFormData(params.data);
                } else if (resource === 'charities' || resource === 'umbrellas') {
                    options.body = getCharityFormData(params.data, resource === 'umbrellas');
                } else {
                    options.body = JSON.stringify(params.data);
                }
                options.method = "POST";

                break;
            case DELETE:
                url = `${apiUrl}/${rolePath}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    const getGuardianJsonData = (guardianData) => {
        let directDebitStartDate = guardianData.direct_debit_start_date;
        if(directDebitStartDate && directDebitStartDate !== 'undefined') {
            directDebitStartDate = moment(guardianData.direct_debit_start_date).format("MM-DD-YYYY")
        }
        return JSON.stringify(
            {
                enabled: guardianData.enabled,
                email: guardianData.email,
                given_name: guardianData.given_name,
                family_name: guardianData.family_name,
                salutation: guardianData.salutation,
                phone: guardianData.phone,
                street: guardianData.street,
                city: guardianData.city,
                postal_code: guardianData.postal_code,
                country: guardianData.country,
                password: guardianData.password,
                child_id: guardianData.child_id,
                direct_debit_reference: guardianData.direct_debit_reference,
                direct_debit_start_date: directDebitStartDate,
                house_number: guardianData.house_number,
                donation_method: guardianData.donation_method,
                iban: guardianData.iban,
                sponsor_as_company: guardianData.sponsor_as_company,
                company: guardianData.company,
                note: guardianData.note
            }
        );
    }

    const getChildFormData = (childData) => {
        // console.log("Child data: " + JSON.stringify(childData));
        let formData = new FormData();
        if (childData.profile_image) {
            formData.append('image', childData.profile_image.rawFile);
        }
        if (childData.profile_video) {
            formData.append('video', childData.profile_video.rawFile);
        }
        formData.append("charity_id", (childData.hasOwnProperty('charity_id') ? childData.charity_id : 0));
        formData.append("first_name", childData.first_name);
        formData.append("last_name", childData.last_name);
        formData.append("birth_date", moment(childData.birth_date).format("YYYY-MM-DD"));
        formData.append("gender", childData.gender);
        formData.append("intro", childData.intro);
        formData.append("family", childData.family);
        formData.append("help", childData.help);
        formData.append("contact_frequency", childData.contact_frequency);
        if (childData.hasOwnProperty('verified')) {
            formData.append("verified", childData.verified);
        }
        if (childData.hasOwnProperty('project_level')) {
            formData.append("project_level", childData.project_level);
        }

        return formData;
    }

    const getCharityFormData = (charityData, isUmbrella) => {
        // console.log("charityData: " + JSON.stringify(charityData));
        let formData = new FormData();
        if (charityData.logo) {
            formData.append('logo', charityData.logo.rawFile);
        }

        // Standard fields
        formData.append("charity_name", charityData.charity_name);
        formData.append("short_name", charityData.short_name);
        formData.append("email", charityData.email);
        formData.append("city", charityData.city);
        formData.append("country", charityData.country);
        formData.append("description", charityData.description);
        formData.append("active", charityData.active);
        formData.append("demo_charity", charityData.demo_charity);

        // Only applicable for an umbrella
        formData.append("send_new_post_mail", charityData.send_new_post_mail);
        formData.append("payment_link_url", charityData.payment_link_url);

        // Only applicable for a project
        if(charityData.parent_charity_id)
            formData.append("parent_charity_id", charityData.parent_charity_id);
        if(charityData.location_id)
            formData.append("location_id", charityData.location_id);
        if(charityData.sponsorship_amount)
            formData.append("sponsorship_amount", charityData.sponsorship_amount);
        if(charityData.days_orange_alert)
            formData.append("days_orange_alert", charityData.days_orange_alert);
        if(charityData.days_red_alert)
            formData.append("days_red_alert", charityData.days_red_alert);
        if(charityData.nr_of_countries)
            formData.append("nr_of_countries", charityData.nr_of_countries);
        if(charityData.nr_of_projects)
            formData.append("nr_of_projects", charityData.nr_of_projects);
        // Optional
        if(charityData.website_link)
            formData.append("website_link", charityData.website_link)
        if(charityData.lat)
            formData.append("lat", charityData.lat);
        if(charityData.lng)
            formData.append("lng", charityData.lng);

        return formData;
    }

    const getPostFormData = (postData) => {
        console.log(JSON.stringify(postData))
        let formData = new FormData();

        if (postData.child_id) {
            formData.append('child_id', postData.child_id);
        }

        if (postData.media_type) {
            formData.append('media_type', postData.media_type);
            formData.append('portrait_mode', postData.portrait_mode);
            if (postData.image) {
                formData.append('image', postData.image.rawFile);
            }
            if (postData.video) {
                formData.append('video', postData.video.rawFile);
            }
        }

        formData.append("description", postData.description);

        return formData;
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const {json} = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!json.hasOwnProperty("page")) {
                    throw new Error(
                        "The page property must be must be present in the Json response"
                    );
                }
                if (!json.page.hasOwnProperty("totalElements")) {
                    throw new Error(
                        "The page.totalElements property must be must be present in the Json response"
                    );
                }

                /* The (HAL) data is contained in the _embedded section of the JSON structure
                 * and then specified by the resource (like children), for example:
                 * { "_embedded" : { "children" : [] } }
                 *
                 * Make sure to return an empty array if no data is found.
                 */
                const targetResource = mapResource(resource, resourceMapper);
                return {
                    data: json.hasOwnProperty('_embedded') ? json._embedded[targetResource] : [],
                    total: parseInt(json.page.totalElements, 10)
                };
            case CREATE:
                // console.log("CREATE json: " + JSON.stringify(json));
                return {data: json, id: json.id};
            //return {data: {...params.data, id: json.id}};
            default:
                return {data: json};
        }
    };

    const toCamel = (s) => {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    };


    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // console.log(type + ',' + resource + ',' + params);
        const role = localStorage.getItem('role');
        const rolePath = role === 'admin' ? 'admin' : role === 'umbrella' ? 'umbrellad' : 'charitad';

        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}}/${rolePath}/${resource}/${id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data)
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json)
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}}/${rolePath}/${resource}/${id}`, {
                        method: "DELETE"
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json)
            }));
        }

        if (type === CREATE) {
        }

        const {url, options} = convertDataRequestToHTTP(type, resource, params);
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};

export default springDataRest;
