import React from 'react';
import {BooleanField, Datagrid, List, TextField} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";

const RNActionList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <List {...props} title='RN Actions' perPage={50}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <TextField source="action_identifier" label='Action identifier' sortable={false}/>
                <TextField source="action_type" label='Action type' sortable={false}/>
                <TextField source="device_type" label='Device type' sortable={false}/>
                <TextField source="url" label='URL' sortable={false}/>
                <BooleanField source="active" label={t('Is active')}/>
            </Datagrid>
        </List>
    );
}

export default RNActionList;
