import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Custom language detector
const customLanguageDetector = {
    type: 'languageDetector',
    async: false,
    detect: () => {
        const lang = navigator.language || navigator.userLanguage;
        return lang.split('-')[0]; // Return only the base language (e.g., 'nl' from 'nl-NL')
    },
    init: () => {},
    cacheUserLanguage: () => {},
};

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(customLanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            // path where resources get loaded from, or a function
            // returning a path:
            // function(lngs, namespaces) { return customPath; }
            // the returned path will interpolate lng, ns if provided like giving a static path
            // the function might return a promise
            // returning falsy will abort the download
            loadPath: '/locales/{{lng}}/translation.json',
        },
        fallbackLng: 'en',
        supportedLngs: ['de', 'en', 'es', 'fr', 'hi', 'hy', 'nl', 'pt', 'ru'], // List the supported languages
        nonExplicitSupportedLngs: true, // Normalize 'nl-NL' to 'nl'
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
